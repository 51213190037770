import React from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import type { ThankYouWidgetProps } from '../ThankYouWidgetController';
import ThankYouWidget from './ThankYouWidget';

export default function (props: WidgetProps<ThankYouWidgetProps>) {
  return (
    <>
      <ThankYouWidget {...props} />
    </>
  );
}
